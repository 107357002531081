<template>
<div>
    <multiselect :disabled="disableValue" v-if="isMultiple" v-model="DisplayValue" :options="options"   :multiple="true" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder="$t('CustomerDropdown.SelectOption')" v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
        <span slot="noResult" class="btn btn-primary " v-on:click="AddCustomer()" v-if="isValid('CanAddCustomer')">{{ $t('CustomerDropdown.CreateQuickCustomer') }}</span><br />
    </multiselect>
    <multiselect :disabled="disableValue" v-else v-model="DisplayValue" :options="options" :multiple="false" track-by="name" ref="multiselect" :searchable="true" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder="$t('CustomerDropdown.SelectOption')" v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
        <span slot="noResult" class="btn btn-primary " v-on:click="AddCustomer()" v-if="isValid('CanAddCustomer')">{{ $t('CustomerDropdown.CreateQuickCustomer') }}</span><br />
    </multiselect>

    <modal :show="show" v-if="show">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">{{ $t('CustomerDropdown.AddCustomer') }}</h6>
                <div class="form-check form-check-inline "  style="color: white !important;padding-left: 90px;">
                    <input v-model="customer.isCashCustomer"  name="contact-sub-type12" id="a49946487" class=" form-check-input" type="radio" v-bind:value="true">
                    <label class="form-check-label pl-0" for="a49946487">Regular</label>
                </div>
                <div class="form-check form-check-inline text-right"  style="color: white !important;">
                    <input v-model="customer.isCashCustomer"  name="contact-sub-type12" id="a9ff8eb45" class=" form-check-input" type="radio" v-bind:value="false">
                    <label class="form-check-label pl-0" for="a9ff8eb45">Retailer</label>
                </div>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body">

                <div class="row ">
                    <div v-if="english=='true'" class="col-sm-12">
                        <label>Name :<span class="text-danger"> *</span></label>
                        <div v-bind:class="{'has-danger' : $v.customer.englishName.$error}">
                            <input class="form-control " @input="DisplayName()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.englishName.$model" />
                            <span v-if="$v.customer.englishName.$error" class="error text-danger">
                                <span v-if="!$v.customer.englishName.required">{{ $t('CustomerDropdown.EngValidation') }} </span>
                                <span v-if="!$v.customer.englishName.maxLength">{{ $t('CustomerDropdown.EngMax') }} </span>
                            </span>
                        </div>
                    </div>
                  
                   
                    <div class="col-sm-12" >
                        <label>{{ $t('CustomerDropdown.RegistrationDate') }} :</label>
                        <div v-bind:class="{'has-danger' : $v.customer.registrationDate.$error}">
                            <datepicker v-model="$v.customer.registrationDate.$model"></datepicker>
                            <span v-if="$v.customer.registrationDate.$error" class="error text-danger">
                            </span>
                        </div>
                    </div>
                  
                  
                    
                    

                    <div class="col-sm-12">
                        <label>{{ $t('AddCustomer.Email') }} :</label>
                        <input class="form-control text-left" v-model="customer.email" />
                    </div>
                    <div class="col-sm-12">
                        <label>Mobile :</label>
                        <input class="form-control text-left" v-model="customer.contactNo1" />
                    </div>

                    <div class="col-sm-12">
                        <label>Address :</label>
                        <div>
                            <textarea class="form-control text-left" v-model="customer.billingAddress" />

                            </div>
                        </div>
                       

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveCustomer" v-bind:disabled="$v.customer.$invalid"> {{ $t('CustomerDropdown.btnSave') }}</button>
                    <button type="button" class="btn btn-soft-secondary btn-sm " v-on:click="close()">{{ $t('CustomerDropdown.btnClear') }}</button>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import moment from 'moment';
import Multiselect from 'vue-multiselect'
import {
    required,
    maxLength,
    
} from "vuelidate/lib/validators"
export default {
    name: 'CustomerDropdown',
    props: ["values", "disable", "paymentTerm", "isMultiple", "isCredit", "formName",'customerList'],
    mixins: [clickMixin],
    components: {
        Multiselect,

    },
    data: function () {
        return {
            b2b: false,
            salutatioRender: 0,
            b2c: false,
            isCashCustomer: false,
            arabic: '',
            english: '',
            contactCode: '',
            cashCustomerCode: '',
            selectedValue: [],
            options: [],
            optionsb2b: [],
            value: '',
            disableValue: false,
            show: false,
            customer: {
                id: '00000000-0000-0000-0000-000000000000',
                category: '',
                code: '',
                registrationDate: '',
                englishName: '',
                arabicName: '',
                vatNo: '',
                customerCode: '',
                customerDisplayName: '',
                contactNo1: '',
                address: '',
                paymentTerms: '',
                isCustomer: true,
                isCashCustomer: true,
                isActive: true,
                customerType:'Customer',
            },
        }
    },
    validations: {
        customer: {
            code: {},
          
            registrationDate: {
                required
            },
            englishName: {
                required,
                maxLength: maxLength(30)
            },
           
        }
    },
    methods: {
        EmptyRecord: function () {

            this.DisplayValue = '';

        },
        Remove: function () {
            this.value='';

        },

        
        asyncFind: function (search) {

            this.getData(true, search);
        },
        GetAutoCodeGenerator: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https
                .get('/Contact/AutoGenerateCode?customerType=' + this.customer.customerType, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {
                        root.customer.code = response.data;

                    }
                });
        },
        DisplayName: function () {
            this.salutatioRender++;
        },
        AddCustomer: function () {

            var name = this.$refs.multiselect.search;


            this.$v.$reset();

            {
                this.customer = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    customerType: 'Customer',
                    paymentTerms: '',
                    category: '',
                    registrationDate: '',
                    englishName: name,
                    arabicName: '',
                    vatNo: '',
                    customerCode: '',
                    contactNo1: '',
                    address: '',
                    customerDisplayName: '',
                    isCustomer: true,
                    isActive: true,
                    isCashCustomer: true,
                }
            }

            if (this.isCredit == true) {
                this.customer.isCashCustomer = false;
            }
           

            this.GetAutoCodeGenerator();
            this.customer.registrationDate = moment().format('llll');
            this.show = !this.show;
        },
        SaveCustomer: function () {
            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
         
            root.$https
                .post('/Contact/SaveContact', this.customer, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                        root.loading = false
                        root.info = response.data.bpi
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true
                        });
                        root.show = !root.show;
                        root.getData(true);
                        root.$emit('input', response.data.message.id);

                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                    root.show = !root.show;
                    root.loading = false
                })
                .finally(() => root.loading = false)
        },
        close: function () {
            this.show = false;
        },
        GetCustomerAddress: function () {

            if (this.value != null && this.value != '') {
                if (this.value.length > 0)
                    return this.value[0];
                else
                    return this.value;
            } else {
                return {
                    address: '',
                    mobile: '',
                    email: '',

                }
            }

        },
      
        getData: function (quick, search) {
            

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            if (quick) {
                root.options = [];
            }
            if (search == undefined || search == null) {
                search = '';
            }
            var paymentTerms = '';
            var multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;

            paymentTerms = this.paymentTerm == 'Credit' || this.paymentTerm == 'آجـل' ? 'Credit' : '';

            this.$https.get('/Contact/ContactList?IsDropDown=' + true + '&isCustomer=' + true + '&isActive=' + true + '&paymentTerms=' + paymentTerms + '&searchTerm=' + search + '&isCashCustomer=' + this.isCashCustomer + '&multipleAddress=' + multipleAddress+ '&customerType=' + this.customer.customerType, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                if (response.data != null) {
                    response.data.results.forEach(function (cust) {
                        if (cust.contactNo1 == null)
                            cust.contactNo1 = '';
                        if (cust.customerCode == null)
                            cust.customerCode = '';

                        var displayName = '';
                       
                         {
                            displayName = cust.englishName;
                        }

                        var addressList = [];
                        if (cust.deliveryAddresses != null) {
                            addressList = cust.deliveryAddresses.filter(x => x.isActive)
                        }
                        root.options.push({
                            id: cust.id,
                            deliveryAddressList: addressList,
                            code: cust.code,
                            customerDisplayName: cust.customerDisplayName,
                            isCashCustomer: cust.isCashCustomer,
                            englishName: cust.englishName,
                            arabicName: cust.arabicName,
                            commercialRegistrationNo: cust.commercialRegistrationNo,
                            vatNo: cust.vatNo,
                            contactNo1: cust.contactNo1,
                            email: cust.email,
                            address: cust.address,
                            shippingAddress: cust.shippingAddress,
                            regionId: cust.regionId,
                            creditDays: cust.creditDays,
                            creditLimit: cust.creditLimit,
                            creditPeriod: cust.creditPeriod,
                            advanceAccountId: cust.advanceAccountId,
                            deliveryAddress: cust.deliveryAddress,
                            shippingOther: cust.shippingOther,
                            deliveryOther: cust.deliveryOther,
                            paymentTerms: cust.paymentTerms,
                            customerCode: cust.customerCode,
                            name: displayName,
                            priceLabelId:cust.priceLabelId,
                            companyNameEnglish: cust.companyNameEnglish,
                            companyNameArabic: cust.companyNameArabic,
                            prefix: cust.prefix,
                        });

                    });
                    
                    if (root.formName == 'ServiceQuotation' || root.formName == 'ServiceSaleOrder' || root.formName == 'ServiceProformaInvoice') {
                        const index = root.options.findIndex(x => x.englishName === 'Walk-In');
                        if (index !== -1) {
                            //   const newObj = { ...root.options[index]};
                            root.options.splice(index, 1);
                        }

                    }
                    
                    else {
                            const index = root.options.findIndex(x => x.englishName === 'Walk-In');
                            if (index !== -1) {
                                //   const newObj = { ...root.options[index]};
                                root.options.splice(index, 1);
                            }

                        }
                }
            }).then(function () {

                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                })
                if (root.value == null || root.value == undefined || root.value == '') {
                    console.log();
                } else {
                    root.$emit('input', root.value.id, root.value.advanceAccountId, root.value);
                }
            });
        },
    },
    computed: {
        DisplayValue: {

            get: function () {
                if (this.value != "" || this.value != undefined) {
                    return this.value;
                }
                return this.values;
            },
            set: function (value) {

                if (value == null || value == undefined) {
                    this.value = value;
                    this.$emit('input', value, value);
                }
                else if (this.isMultiple == true) {
                    this.$emit('input', value, value);

                }
                else {

                    this.value = value;
                    this.$emit('input', value.id, value.advanceAccountId, value);
                }
            }
        }
    },
    mounted: function () {

        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');

        this.getData();
        this.disableValue = this.disable;
    },
}
</script>
